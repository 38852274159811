.App {
  text-align: center;
}




.App button {
  background-color: #0054f7;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 20px;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-bottom: 20px;
}

.button-1:hover,
.button-1:focus {
  background-color: #F082AC;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-main {
  width: 100vw;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-main p {
  max-width: min(600px, 90vw);
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  line-height: 1.3;
  font-size: 1.2rem;
}



.hiddenstuff {
margin-top: 88px;

}

.App-link {
  color: #61dafb;
}

.header-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.subheader {

  margin-bottom: 8rem;
  background-color: #FFFFFF;
  color: #0054f7;
  font-size: 1.2rem;
}

.subheader span {

  font-size: 1.2rem !important;

}

.gemic-logo {
width: calc(0.07*2928px);
height: calc(0.07*687px);
margin-right: 30px;
}

.meta-logo {
  width: calc(0.4*640px);
  height: calc(0.4*188px);
  margin-left: 30px;
}

@media only screen and (max-width: 670px) {

  .gemic-logo {
    width: calc(10vw*2.928);
    height: calc(10vw*0.687);
    margin-left: 5vw;
  }
    
    .meta-logo {
      width: calc(55vw*0.640);
      height: calc(55vw*0.188);
      margin-left: 5vw;
    }

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
